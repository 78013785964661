import React, { useState, useEffect } from 'react';
import Confetti from 'react-confetti';
import './App.css';

const mapEmbedUrl = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2946.040482369092!2d-82.18197692250217!3d42.40558297118848!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882ff856dfa67bd5%3A0x200f7094e64716d6!2sSons%20of%20Kent%20Brewing%20Company!5e0!3m2!1sen!2sca!4v1720649747587!5m2!1sen!2sca'; // Example embed URL

function ImageDisplay() {
  const [clicked, setClicked] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    if (clicked === 'second') {
      document.body.style.backgroundImage = `url('/background2.png')`;
    }
  }, [clicked]);

  const handleClick = () => {
    setClicked(true);
    setShowConfetti(true);
    setTimeout(() => {
      setShowConfetti(false);
      setClicked('second');
    }, 3000); // 3-second delay for confetti
  };

  const toggleFormDisplay = () => {
    setShowForm(!showForm);
  };


  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.target;
    const formData = new FormData(form);

    try {
      const response = await fetch('/api/submit', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const responseData = await response.text();
        alert(responseData);
      } else {
        const errorData = await response.text();
        alert(`Message submission failed: ${errorData}`);
      }
    } catch (error) {
      alert(`Error submitting message: ${error.message}`);
    }

    setShowForm(false);
  };

  return (
    <div className="App">
      {showConfetti && (
        <div style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', pointerEvents: 'none' }}>
          <Confetti width={window.innerWidth} height={window.innerHeight} />
        </div>
      )}
      {!clicked && (
        <>
          <img
            src="/closed.png"
            alt="Display"
            onClick={handleClick}
            style={{ width: '100%', maxHeight: '80vh', cursor: 'pointer', objectFit: 'contain' }}
          />
          <div className="click-me-text">
          <img src="/clickme.png" alt="Click Me" />
          </div>
        </>
      )}
      {clicked && clicked !== 'second' && (
        <>
          <img
            src="/open.png"
            alt="Display"
            style={{ width: '100%', maxHeight: '80vh', objectFit: 'contain' }}
          />
        </>
      )}
      {clicked === 'second' && (
        <div className="content-container">
          <div style={{ textAlign: 'center' }}>
          <h1>You are Invited to Steve's Secret Surprise Retirement Party!</h1>
            <p>Come on by and have a drink to celebrate! All are welcome, snacks provided.</p>
            <p><b>Date:</b> August 10th, 2024</p>
            <p><b>Time:</b> 1:00-4:00 PM</p>
            <p><b>Location:</b> 27 Adelaide St S, Chatham, ON N7M 4P9</p>
            <div className="iframe-container">
              <iframe
                src={mapEmbedUrl}
                style={{ width: '100%', height: '100%', border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Party Location"
              ></iframe>
            </div>

            <div style={{ marginTop: '20px' }}>
              <button onClick={toggleFormDisplay} className={showForm ? 'grey' : ''} style={{ padding: '10px 20px', backgroundColor: showForm ? '#808080' : '#4CAF50', color: 'white', border: 'none', cursor: 'pointer' }}>
                {showForm ? "Close" : "Can't make it?"}
              </button>
            </div>

            {showForm && (
              <div style={{ marginTop: '20px' }}>
                <p>Would you like to leave a message for Steve in the Retirement Journal that will be at the party?</p>
                <form onSubmit={handleSubmit} style={{ textAlign: 'center' }}>
                  <div>
                    <label htmlFor="name"><b>Full Name</b></label>
                    <input id="name" name="name" type="text" required />
                  </div>
                  <div>
                    <label htmlFor="message"><b>Message</b></label>
                    <textarea id="message" name="message" required></textarea>
                  </div>
                  <button type="submit">Submit</button>
                </form>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default ImageDisplay;
